<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Meta Keywords - Přidat</strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item
        label="Název"
          :validate-status="nameError() ? 'error' : ''"
          :help="nameError() || ''"
        >
          <a-input @change="handleChange" placeholder="Název" v-decorator="['mkd_name', {rules: [{max: 30, message: 'Název nemůže být delší než 30 znaků'}, {required: true, message: 'Název musí být vyplněn!'}]}]"/>
        </a-form-item>
        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">Přidat</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../services/forms'

export default {
  components: { ActionTools, LanguageTab },
  data: function () {
    return {
      hasErrors,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/meta-keywords',
          title: 'Seznam',
        },
      ],
      form: this.$form.createForm(this),
      items: [],
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('metaKeyword/post', this.items.filter(x => x.mkd_name.trim().length > 0))
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    handleChange(e) {
      let found = false
      this.items.some((value, key) => {
        if (value.lge_id === this.$store.getters['language/active']) {
          this.items[key] = { lge_id: this.$store.getters['language/active'], mkd_name: e !== undefined ? e.target.value : '' }
          found = true
          return true
        }
      })
      if (!found) {
        this.items.push(
          { lge_id: this.$store.getters['language/active'], mkd_name: e !== undefined ? e.target.value : '' },
        )
      }
    },
    nameError() {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched('mkd_name') && getFieldError('mkd_name')
    },
  },
  created() {
    this.$store.watch(
      (state, getters) => getters['language/active'], () => {
        if (this.$store.getters['language/active'] !== null) {
          const foundObj = this.items.find(x => x.lge_id === this.$store.getters['language/active'])
          this.form.setFieldsValue({
            mkd_name: foundObj === undefined ? '' : foundObj.mkd_name,
          })
          this.form.validateFields()
        }
      },
    )
  },
}
</script>
